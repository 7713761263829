// App dev
export const environment = {
  production: false,
  graphQlUrl: 'https://api-dev.syzl.io/graphql',
  serverUrl: 'https://api-dev.syzl.io/api',
  serverRootUrl: 'https://api-dev.syzl.io',
  appUrl: 'https://app-dev.syzl.io',
  appImageUrl: 'https://app.syzl.io/assets/img/syzl.png',
  appId: '6JMrpjIj8L',
  fbId: '305270171577914',
  googleMapsApiKey: 'AIzaSyDyv4MbjwrZJxQCf_L0YiXM9ElfPbfDm8A',
  androidHeaderColor: '#0027FF',
  defaultUnit: 'km',
  defaultLang: 'en',
  defaultSpaceNeeded: 1,
  googleClientId: '222858438652-qvlmhlb3qbhmtjuvfp0k6dj6rsp8qe8a.apps.googleusercontent.com',
  gcsBucketPrefix: 'dev-',
  cloudinaryUrl: 'https://syzl-optimizer.mo.cloudinary.net/syzl-dev/',
  grantType:'client_credentials',
  stripePublicKey: 'pk_test_51K0LHUG7W2eb3jPinmckYfy6QWUYK9EJsdlveVhGoghhIbOWckNTGYsKGt7VZb9xCZntGRAzSyndwsJGBURomkkw00Wjnwkmi5',
  oneSignal: {
    appId: '0ba03539-4025-40b0-968c-8d8f6e51c04a',
    googleProjectNumber: '222858438652',
    webhookUrl: 'https://api-dev.syzl.io//onesignal/webhook'
  },
  currency: {
    code: 'CAD',
    display: 'symbol',
    digitsInfo: '1.2-2',
  },

  // 0ba03539-4025-40b0-968c-8d8f6e51c04a
  // admob: {
  //   banner: {
  //     android: 'ca-app-pub-xxxxxxxxxxxxxxxxxxxx',
  //     ios: 'ca-app-pub-xxxxxxxxxxxxxxxxxxxx',
  //   },
  // },
  firebaseConfig: {
    apiKey: 'AIzaSyDymx2MfM_eodBnyZKHoowzUvsTF68zQuE',
    authDomain: 'syzl-dev.firebaseapp.com',
    projectId: 'syzl-dev',
    storageBucket: 'syzl-dev.appspot.com',
    messagingSenderId: '222858438652',
    appId: '1:222858438652:web:5bc86c1070adce5a483479',
    measurementId: 'G-BQ3DDG504S'
  },
  recaptchaConfig: {
    siteKey: '6LeM_h4fAAAAAHlgq5AABrH5-GTtpZBLXM0-d-Tx'
  },
  pendoApiKey: '2fef5628-3fdf-416a-5849-7fd65da2fccb',
  personaEnvironment: 'sandbox',
  personaTemplateId: 'itmpl_NfgV5SseX7eBHcmox5nnxTeP',
  personaDocumentTemplateId: 'itmpl_1mWbZdKVDX8ERUteRjy52gg4',
};

