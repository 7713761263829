import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './httpConfig.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, isPlatform } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { EmptyViewModule } from './components/empty-view/empty-view.module';
import { NotificationPageModule } from './pages/notification/notification.module';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { ModalManagerService } from './modals/modal-manager.service';
import { ModalsModule } from './modals/modals.module';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { SignInSocialCompletionModule } from './pages/sign-in-social-completion/sign-in-social-completion.module';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxPendoModule } from 'ngx-pendo';
import { PlaceholderPage } from './components-standalone/placeholder/placeholder.component';

export function playerFactory() {
  return player;
}

const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');
SwiperCore.use([Pagination, Navigation, Autoplay]);

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      backButtonText: '',
      backButtonIcon: 'chevron-back-outline',
      animated: true,
      rippleEffect: true,
      mode: 'md',
    }),
    AppRoutingModule,
    EmptyViewModule,
    NotificationPageModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && !isPlatform('capacitor'),
    }),
    ModalsModule,
    SignInSocialCompletionModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxPendoModule.forRoot({
      pendoApiKey: environment.pendoApiKey,
      pendoIdFormatter: (pendoId: string) => pendoId.toLowerCase()
    }),
    PlaceholderPage
  ],
  providers: [
    InAppBrowser,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => ({
        cache: new InMemoryCache(),
        link: httpLink.create({
          uri: environment.graphQlUrl,
          headers: new HttpHeaders()
            .set('X-Parse-Application-Id', environment.appId)
        }),
      }),
      deps: [HttpLink],
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS,  useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // The ModalManagerService needs to be loaded prior to any modals to avoid circular injection errors
  constructor(
    private _modalManager: ModalManagerService
  ) {}
}
